import * as React from 'react'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

import 'modern-normalize'
import styles from '../styles/normalize'
import styled from '@emotion/styled'
import Navbar from '../components/Navbar'
import { css, Global } from '@emotion/core'
import Footer from '../components/Footer'

const Layout = styled.div`
  width: 100%;
  height: 100%;
`

const MainLayout: React.FC = ({ children }) => {
  const data = useStaticQuery(graphql`
    query IndexLayoutQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)
  return (
    <Layout>
      <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          { name: 'description', content: data.site.siteMetadata.description },
          { name: 'keywords', content: data.site.siteMetadata.keywords }
        ]}
      />
      <Global styles={css(styles)} />
      <Navbar />
      <main>{children}</main>
      <Footer />
    </Layout>
  )
}

export default MainLayout
