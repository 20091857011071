import React, { forwardRef } from 'react'
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const Logo = forwardRef<any, any>((props, ref) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
  <div className="">
    <Img fluid={data.image.childImageSharp.fluid} />
  </div>
  )
})

export default Logo
