export const YT_CHANNEL_URL = 'https://www.youtube.com/channel/UCwzM-9u-_cq_ejUcb-d70Zw'

export const FB_PAGE_URL = 'https://fb.com/zuk.festival'

export const IG_PAGE_URL = 'https://instagram.com/zuk.festival'

export const MAIL = 'zuk.festival@gmail.com'

export const APPLY_PATH = 'https://tripetto.app/run/MZR7YED5E6'

export const RULES_URL = 'https://docs.google.com/document/d/14Tjc2SfcG1AvFc6FeRwLXT077ga3yqOqZ6lPB3YZvP8/edit?usp=sharing'
