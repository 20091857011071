import { IoLogoFacebook, IoLogoInstagram, IoLogoYoutube, IoMdClose, IoMdMenu } from 'react-icons/io'
import * as React from 'react'
import styled from '@emotion/styled'
import { FC, useState } from 'react'
import { Link } from 'gatsby'
import { colors } from '../styles/variables'
import Logo from './Logo'
import { APPLY_PATH, FB_PAGE_URL, IG_PAGE_URL, RULES_URL, YT_CHANNEL_URL } from "../data/urls";

const NavbarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colors.black};
  z-index: 2;
`
const Menu = styled.ul<{ isMenuOpen: boolean }>`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);

  li {
    display: block;
    margin: 0 20px;

    &:first-of-type {
      margin-left: 0;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
  }
  @media (max-width: 930px) {
    top: 100%;
    left: 0;
    transform: none;
    width: 100%;
    z-index: 2;
    display: ${props => (props.isMenuOpen ? 'block' : 'none')};
    background: black;
  }
  li {
    @media (max-width: 930px) {
      margin: 20px 0;
    }
  }
`
const Brand = styled(Logo)`
  height: 3em;
  width: auto;
`
const Actions = styled.div`
  svg {
    height: 2em;
    width: 2em;
    margin: 0 10px;

    &:last-of-type {
      margin-right: 0;
    }
  }
  a {
    color: inherit;
    text-decoration: none;
    transition: 0.2s all;
  }
  .fb:hover {
    color: ${colors.secondary};
  }
  .ig:hover {
    color: ${colors.tertiary};
  }
  .yt:hover {
    color: ${colors.primary};
  }
`
const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
`
const MenuSwitcher = styled.button`
  font-size: 1.5em;
  margin-left: 20px;
  margin-top: 5px;
  background: none;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  @media (min-width: 931px) {
    display: none;
  }
`
const Navbar: FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  return (
    <NavbarWrapper>
      <LeftWrapper>
        <Brand />
        <MenuSwitcher onClick={() => setMenuOpen(!isMenuOpen)}>{isMenuOpen ? <IoMdClose /> : <IoMdMenu />}</MenuSwitcher>
      </LeftWrapper>
      <Menu isMenuOpen={isMenuOpen}>
        <li>
          <Link to="/">O Festiwalu</Link>
        </li>
        {/*<li>*/}
        {/*  <Link to="/artists">Artyści</Link>*/}
        {/*</li>*/}
        <li>
          <a href={RULES_URL} target="_blank" rel="noreferrer noopener">Regulamin</a>
        </li>
        <li>
          <a href={APPLY_PATH} target="_blank" rel="noreferrer noopener">Zgłoś się</a>
        </li>
      </Menu>

      <Actions>
        <a href={FB_PAGE_URL} target="_blank" rel="noreferrer noopener" className={'fb'}>
          <IoLogoFacebook />
        </a>
        <a href={IG_PAGE_URL} target="_blank" rel="noreferrer noopener" className="ig">
          <IoLogoInstagram />
        </a>
        <a href={YT_CHANNEL_URL} target="_blank" rel="noreferrer noopener" className={'yt'}>
          <IoLogoYoutube />
        </a>
      </Actions>
    </NavbarWrapper>
  )
}

export default Navbar
