import * as React from 'react'
import styled from '@emotion/styled'
import Container from './Container'

const FooterWrapper = styled.footer`
  padding: 10px 0;
`
const FooterInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const year = new Date().getFullYear()

const Footer = () => {
  return (
    <FooterWrapper>
      <Container>
        <FooterInnerWrapper>
          <span>&copy; {year} Samorząd XL LO</span>
          <span>
            {'</>'} with ❤️ by <a href="https://micorix.dev">micorix</a>
          </span>
        </FooterInnerWrapper>
      </Container>
    </FooterWrapper>
  )
}

export default Footer
